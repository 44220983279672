import productJson from './product.json';
import blogJson from './blog.json';
/**

*/

function allProductData() {
  return productJson;
}

function generateMockProductData(count, tag = 'powder') {
  const products = productJson;
  const filtered = products.filter((item) => item.tags.includes(tag));
  return filtered;
}

function filterProductData(tag = 'powder') {
  const products = productJson;
  const filtered = products.filter((item) =>
    item.tags.includes(tag.toLocaleLowerCase())
  );
  return filtered;
}

function searchProductData(search = '') {
  const products = productJson;
  const filtered = products.filter((item) =>
    item.alt.includes(search.toLocaleLowerCase())
  );
  return filtered;
}

function generateMockBlogData(count) {
  return blogJson.slice(0, count);
}

export {
  generateMockProductData,
  generateMockBlogData,
  searchProductData,
  filterProductData,
  allProductData,
};
